import React, { useEffect } from "react";

const CenterToLeft = ({ text }) => {
  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".hiddenScrollForTrans") &&
        document
          .querySelector(".hiddenScrollForTrans")
          .classList.remove("hiddenScrollForTrans");
    }, 900);
  }, []);
  return (
    <div className="centerToLeft">
      <div className="helloTrans--dot"></div>
      <p className="helloTrans--text">{text}</p>
    </div>
  );
};

export default CenterToLeft;
