import React from "react";
import DownloadCv from "./DownloadCv";
import { useSwipeable } from "react-swipeable";
import { useFirebase } from "../hooks/useFirebase";

const Footer = ({ setSwipeToNext, setSwipeToPrev }) => {
  const { user } = useFirebase();

  // navigation by swipe
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setSwipeToNext && setSwipeToNext(true);
    },
    onSwipedRight: () => {
      setSwipeToPrev && setSwipeToPrev(true);
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    delta: user?.swipeDelta ?? 75,
  });
  return (
    <div {...handlers} className="footer">
      <div style={{ maxWidth: "90%" }}>
        <DownloadCv reverseColor={true} />
      </div>
    </div>
  );
};

export default Footer;
