import React from "react";

const LeftToRightEmpty = ({ text }) => {
  return (
    <div className="leftToRightEmpty">
      <div className="helloTrans--dot"></div>
      <p className="helloTrans--text">{text}</p>
    </div>
  );
};

export default LeftToRightEmpty;
