import React, { useState, useEffect } from "react";
import { useFirebase } from "../../hooks/useFirebase";
import { Carousel } from "react-responsive-carousel";
import CancelIcon from "@mui/icons-material/Cancel";
import SloppedText from "../SloppedText";

const BigCard = ({ setIsVisible, data, setCurrentIndex, currentIndex }) => {
  return (
    <div className="bigCard unSwipeable">
      <div className="bigCard__content unSwipeable">
        <div
          className="bigCard__content--close unSwipeable"
          onClick={() => {
            {
              document
                .querySelector(".bigCard")
                .classList.remove("bigCard--animBackdrop");
              document
                .querySelector(".bigCard__content")
                .classList.remove("bigCard--animOpen");
              setIsVisible(false);
            }
          }}
        >
          <CancelIcon style={{ color: "red", height: 40, width: 40 }} />
        </div>
        <h2 className="bigCard__content--title unSwipeable">
          {data?.title.toUpperCase()}
        </h2>
        <div className="bigCard__content__carousel unSwipeable">
          <Carousel
            selectedItem={currentIndex}
            onChange={(index) => setCurrentIndex(index)}
            autoPlay={true}
            interval={6000}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
          >
            {data?.images.map((el) => {
              return (
                <div className="bigCard__content__carousel__imgContainer unSwipeable">
                  <img
                    src={el}
                    className="bigCard__content__carousel__imgContainer--img"
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className="bigCard__content__infos unSwipeable">
          <p className="unSwipeable">{data?.isMobile ? "~Mobile" : "~Web"}</p>
          <p className="unSwipeable">Durée: {data?.duration}mois</p>
        </div>
        <SloppedText
          text={"Description"}
          style={{ marginTop: 10 }}
          unSwipeable={true}
        />
        <p className="unSwipeable">{data?.description}</p>
        <SloppedText
          text={"Principales tâches accomplies"}
          style={{ marginTop: 40 }}
          unSwipeable={true}
        />
        <div className="bigCard__content__taskContainer unSwipeable">
          {data?.points.map((el) => {
            return (
              <div className="bigCard__content__taskContainer__task unSwipeable">
                <div className="bigCard__content__taskContainer__task--dot unSwipeable"></div>
                <p className="bigCard__content__taskContainer__task--text unSwipeable">
                  {el}
                </p>
              </div>
            );
          })}
        </div>
        <SloppedText
          text={"Stack"}
          style={{ marginTop: 40 }}
          unSwipeable={true}
        />
        <div className="bigCard__content__technosContainer unSwipeable">
          {data?.technos.map((el) => {
            return (
              <div className="bigCard__content__technosContainer__technos unSwipeable">
                <p className="bigCard__content__technosContainer__technos--text unSwipeable">
                  {el}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BigCard;
