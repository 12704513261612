import React, { createContext, useContext, useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import { getAuth } from "@firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  onSnapshot,
  doc,
} from "@firebase/firestore";
import { getStorage } from "@firebase/storage";

const config = {
  apiKey: "AIzaSyAnTuZDtWY11gPyO3eHUc7CUYBrMtnjyT0",
  authDomain: "portfolio-95295.firebaseapp.com",
  projectId: "portfolio-95295",
  storageBucket: "portfolio-95295.appspot.com",
  messagingSenderId: "384949981009",
  appId: "1:384949981009:web:c1d6359647762124864d70",
  measurementId: "G-9TH5JG0VQ3",
};

const firebaseApp = initializeApp(config, "app");
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export const FirebaseContext = createContext();
export const useFirebase = () => useContext(FirebaseContext);

const FirebaseContextProvider = (props) => {
  const [projects, setProjects] = useState([]);
  const [recommandations, setRecommandations] = useState([]);
  const [user, setUser] = useState();
  useEffect(() => {
    (async () => {
      setProjects([]);
      setRecommandations([]);
      const snap = await getDocs(collection(db, "projects"));
      const recommandationsSnap = await getDocs(
        collection(db, "recommandations")
      );
      const userSnap = await getDoc(doc(db, "users", "camara"));
      let tmp = await Promise.all(
        snap.docs.map((el, i) => {
          const data = {
            ...el.data(),
            id: el.id,
            ref: el.ref,
          };
          return data;
        })
      );
      let tmpRecommandations = await Promise.all(
        recommandationsSnap.docs.map((el, i) => {
          const data = {
            ...el.data(),
            id: el.id,
            ref: el.ref,
          };
          return data;
        })
      );
      setProjects((prev) => [...tmp]);
      setRecommandations((prev) => [...tmpRecommandations]);
      setUser(userSnap.data());
    })();
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        auth,
        db,
        storage,
        projects,
        recommandations,
        user,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContextProvider;
