import React, { useEffect, useState } from "react";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Typewriter from "typewriter-effect";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useFirebase } from "../hooks/useFirebase";

const DownloadCv = ({ reverseColor }) => {
  const { db, storage } = useFirebase();
  const strings = [
    "Télécharger mon CV",
    "Télécharger mon CV",
    "--> CV <--",
    "Télécharger mon CV",
    "Curriculum vitae",
  ];
  const [animStart, setAnimStart] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimStart(true), 2500);
  }, []);

  const handleDownload = async () => {
    const url = await getDownloadURL(ref(storage, "cv_firebase.pdf"));
    window.open(url, "_blank");
  };
  return (
    <div
      className="downloadCv"
      style={{ backgroundColor: reverseColor ? "white" : "#141517" }}
      onClick={handleDownload}
    >
      <DownloadForOfflineIcon
        style={{ marginRight: 10, color: reverseColor ? "#141517" : "white" }}
      />
      <Typewriter
        style={{ fontSize: 100, color: reverseColor ? "white" : "#141517" }}
        options={{
          autoStart: animStart,
          loop: true,
          pauseFor: 5000,
          wrapperClassName: reverseColor
            ? "reverseWrapperColor"
            : "Typewriter__wrapper",
          cursorClassName: reverseColor
            ? "reverseWrapperColor Typewriter__cursor"
            : "Typewriter__cursor",
          strings,
        }}
      />
    </div>
  );
};

export default DownloadCv;
