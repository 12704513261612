import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TransitionHandler, {
  navigateToNextPage,
  navigateToPrevPage,
} from "./transition/TransitionHandler";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "./Menu";
import { useDimensions } from "../hooks/useDimensions";
import { useSwipeable } from "react-swipeable";
import { delay } from "../hooks/exportableFuntions";

export const pagesTranslate = {
  "/": "Accueil",
  "/about": "Qui suis-je ?",
  "/portfolio": "Mes projets",
  "/contact": "Contact",
};

export const allPages = ["/", "/about", "/portfolio", "/contact"];

const BottomNav = ({ isHome, swipeToNext, swipeToPrev }) => {
  const route = useLocation();
  const navigate = useNavigate();
  const { screenHeight, screenWidth } = useDimensions();
  const isNextPage = route?.state?.isNextPage || null;
  const previousUrl = route?.state?.previousUrl || null;
  const isPrevPage = route?.state?.isPrevPage || null;
  const [disableNext, setDisableNext] = useState(false);
  const [nextText, setNextText] = useState("");
  const [disablePrev, setDisablePrev] = useState(false);
  const [prevText, setPrevText] = useState("");
  const [newPage, setNewPage] = useState("");
  const [showButtons, setShowButtons] = useState(true);
  const [keyboardActive, setKeyboardActive] = useState(false);

  useEffect(() => {
    if (route.pathname === "/") {
      setDisablePrev(true);
    }
    if (route.pathname === "/contact") {
      setDisableNext(true);
    }
    const index = allPages.findIndex((item) => route.pathname === item);
    setNextText(pagesTranslate[allPages[index + 1]]);
    setPrevText(pagesTranslate[allPages[index - 1]]);
  }, []);

  // navigation by swipe handler
  useEffect(() => {
    swipeToNext && handleNext();
    swipeToPrev && handlePrev();
  }, [swipeToNext, swipeToPrev]);

  const handlePrev = () => {
    const index = allPages.findIndex((item) => route.pathname === item);
    setNewPage(pagesTranslate[allPages[index - 1]]);
    navigateToPrevPage(() => {
      navigate(allPages[index - 1], {
        state: { isPrevPage: true, previousUrl: route.pathname },
      });
    });
  };

  const handleNext = () => {
    const index = allPages.findIndex((item) => route.pathname === item);
    setNewPage(pagesTranslate[allPages[index + 1]]);
    navigateToNextPage(() => {
      navigate(allPages[index + 1], {
        state: { isNextPage: true, previousUrl: route.pathname },
      });
    });
  };

  // const handleArrowKeyPress = (event) => {
  //   setKeyboardActive(true);
  //   console.log(keyboardActive);
  //   if (keyboardActive) return;
  //   if (event?.key === "ArrowLeft") {
  //     console.log("Flèche gauche pressée");
  //     handlePrev();
  //   }
  //   if (event?.key === "ArrowRight") {
  //     setKeyboardActive(true);
  //     handleNext();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("keydown", async (e) => {
  //     handleArrowKeyPress(e);
  //     await delay(5000);
  //   });
  // }, []);

  return (
    <>
      <div
        className="bottomNav__menuButton"
        onClick={() => {
          document.querySelector(".menu").classList.remove("menu--close");
          document.querySelector(".menu").classList.add("menu--open");
          setShowButtons(false);
        }}
      >
        <MenuIcon style={{ color: "white" }} />
      </div>
      <Menu setNewPage={setNewPage} setShowButtons={setShowButtons} />
      {showButtons && !disablePrev && (
        <button
          className="bottomNav--prev"
          onClick={handlePrev}
          id="navigationPrevButton"
          style={{
            position: "fixed",
            bottom: 20,
            left: 0,
            zIndex: 3,
            display: screenWidth > 1100 ? "flex" : "none",
          }}
        >
          <KeyboardDoubleArrowLeftIcon
            style={{ color: "white", marginRight: 10 }}
          />
          {prevText}
        </button>
      )}
      {showButtons && !disableNext && (
        <button
          onClick={handleNext}
          className="bottomNav--prev"
          id="navigationNextButton"
          style={{
            boxShadow: " #fff -4px 4px 0 0, #000 -4px 4px 0 1px",
            marginRight: 50,
            position: "fixed",
            bottom: 20,
            right: 0,
            zIndex: 3,
            display: screenWidth > 1100 ? "flex" : "none",
          }}
        >
          {nextText}
          <KeyboardDoubleArrowLeftIcon
            style={{
              color: "white",
              marginLeft: 10,
              transform: "rotate(180deg)",
            }}
          />
        </button>
      )}
      <TransitionHandler
        isHome={isHome}
        disableHomeTrans={!previousUrl ? false : true}
        isPrevPage={isPrevPage}
        isNextPage={isNextPage}
        actualPage={pagesTranslate[route.pathname]}
        newPage={newPage}
      />
      {/* <div
        className="bottomNav"
        style={{ justifyContent: disablePrev ? "flex-end" : "space-between" }}
      >
      </div> */}
    </>
  );
};

export default BottomNav;
