import React, { useEffect, useState } from "react";
// import { loadSlim } from "tsparticles-slim";
import BottomNav from "../components/BottomNav";
import { useLocation } from "react-router-dom";
import SloppedText from "../components/SloppedText";
import Subtitle from "../components/Subtitle";
import StarryBackground from "../components/StarryBackground";
import Footer from "../components/Footer";
import { addDoc, collection } from "firebase/firestore";
import { useFirebase } from "../hooks/useFirebase";
import { useSwipeable } from "react-swipeable";
import { useDimensions } from "../hooks/useDimensions";
import { delay } from "../hooks/exportableFuntions";
// import sgMail from "@sendgrid/mail";
// import { API_KEY_SENDGRID } from "../utils/variables";

// sgMail.setApiKey(API_KEY_SENDGRID);

const Contact = () => {
  const { db, user } = useFirebase();
  const { screenWidth } = useDimensions();
  const initalState = { value: "", isValid: false };
  const [swipeToPrev, setSwipeToPrev] = useState(false);
  const [name, setName] = useState(initalState);
  const [phone, setPhone] = useState(initalState);
  const [email, setEmail] = useState(initalState);
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState({
    value: "",
    isValid: false,
  });
  const [messageLength, setMessageLength] = useState(message.value.length);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    value: "Veuillez correctement saisir tous les champs !",
  });

  useEffect(() => {
    (async () => {
      await delay(900);
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSuccessMessage(false);
    setShowErrorMessage({ show: false, value: "" });
    if (new Date() < new Date(localStorage.getItem("messageTimer"))) {
      setShowErrorMessage({
        show: true,
        value: "Vous pouvez envoyer un message toutes les 2 heures.",
      });
      return;
    }
    if (!name.isValid || !phone.isValid || !email.isValid || !message.isValid) {
      setShowErrorMessage({
        show: true,
        value: "Veuillez correctement saisir tous les champs !",
      });
      return;
    }
    try {
      await addDoc(collection(db, "messages"), {
        name: name.value,
        phone: phone.value,
        email: email.value,
        company,
        message: message.value,
        createdAt: new Date(),
      });
      // const msg = {
      //   to: "m.salimcamara@gmail.com",
      //   from: email.value,
      //   subject: "Portfolio",
      //   text: `${name.value} ${phone.value} ${message.value}`,
      // };
      const dateActuelle = new Date();
      const dateDans24Heures = new Date(
        dateActuelle.getTime() + 2 * 60 * 60 * 1000
      );
      localStorage.setItem("messageTimer", dateDans24Heures);
      setShowSuccessMessage(true);
      // await sgMail.send(msg);
    } catch (e) {
      console.error("Erreur envoie message: ", e);
    }
  };

  // navigation by swipe
  const handlers = useSwipeable({
    onSwipedRight: () => {
      setSwipeToPrev(true);
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    delta: user?.swipeDelta ?? 75,
  });

  return (
    <div {...handlers} className="scrollDiv">
      <div className="contact" id="particules-js">
        <StarryBackground />
        <SloppedText
          text={"Contact"}
          styleText={{ fontSize: screenWidth < 1100 ? 30 : 60 }}
        />
        <Subtitle
          text={"Mes coordonnées"}
          style={{ fontSize: screenWidth < 1100 ? 20 : 30 }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="contact__coorContainer">
            <div className="contact__coorContainer__phoneContainer">
              <p className="contact--text" style={{ margin: 0 }}>
                Téléphone: {user?.phone ?? "06 95 61 13 91"}
              </p>
              <p className="contact--text" style={{ margin: 0, fontSize: 15 }}>
                (Appelez ou laissez-moi un message à tout moment)
              </p>
            </div>
            <div className="contact__coorContainer__emailContainer">
              <p className="contact--text" style={{ margin: 0 }}>
                Email: {user?.email ?? "m.salimcamara@gmail.com"}
              </p>
            </div>
          </div>
        </div>
        <Subtitle
          text={"Mes réseaux"}
          style={{ fontSize: screenWidth < 1100 ? 20 : 30 }}
        />
        <div className="contact__social">
          <div
            className="contact__social__iconContainer"
            onClick={() =>
              window.open(
                `${
                  user?.linkedin ??
                  "https://www.linkedin.com/in/salim-camara-879b411b8/"
                }`,
                "_blank"
              )
            }
          >
            <p className="contact__social__iconContainer--title contact--text">
              LinkedIn
            </p>
            <img
              src={require("./../assets/images/icon_linkedin.png")}
              className="contact__social--icons"
            />
          </div>
          <div
            className="contact__social__iconContainer"
            onClick={() =>
              window.open(
                `${user?.github ?? "https://github.com/Salim-camara"}`,
                "_blank"
              )
            }
          >
            <p className="contact__social__iconContainer--title contact--text">
              Github
            </p>
            <img
              src={require("./../assets/images/icon_github.png")}
              className="contact__social--icons"
            />
          </div>
        </div>
        <Subtitle
          text={"Ecrivez-moi !"}
          style={{ fontSize: screenWidth < 1100 ? 20 : 30 }}
        />
        <form className="contact__form">
          <div className="contact__form__container">
            <label
              className="contact__form--labels contact--text"
              style={screenWidth < 1100 ? { marginTop: 0 } : {}}
            >
              Nom *
            </label>
            <input
              type="text"
              className="contact__form--inputs input--name"
              value={name.value}
              onChange={(e) => {
                const regexTest = /^[A-Za-z]+$/;
                if (regexTest.test(e.target.value)) {
                  document.querySelector(".input--name").style.boxShadow =
                    "green 4px 4px 0 0, #141517  4px 4px 0 1px";
                  setName({
                    value: e.target.value,
                    isValid: true,
                  });
                } else {
                  document.querySelector(".input--name").style.boxShadow =
                    "red 4px 4px 0 0, #141517 4px 4px 0 1px";
                  setName({
                    value: e.target.value,
                    isValid: false,
                  });
                }
              }}
            />
          </div>
          <div className="contact__form__container">
            <label className="contact__form--labels contact--text">
              Téléphone *
            </label>
            <input
              className="contact__form--inputs input--phone"
              value={phone.value}
              onChange={(e) => {
                const regexTest = /^(0|\+33|0033)[1-9](\d{2}){4}$/;
                if (regexTest.test(e.target.value)) {
                  document.querySelector(".input--phone").style.boxShadow =
                    "green 4px 4px 0 0, #141517  4px 4px 0 1px";
                  setPhone({
                    value: e.target.value,
                    isValid: true,
                  });
                } else {
                  document.querySelector(".input--phone").style.boxShadow =
                    "red 4px 4px 0 0, #141517 4px 4px 0 1px";
                  setPhone({
                    value: e.target.value,
                    isValid: false,
                  });
                }
              }}
            />
          </div>
          <div className="contact__form__container">
            <label className="contact__form--labels contact--text">
              Email *
            </label>
            <input
              type="text"
              className="contact__form--inputs input--email"
              value={email.value}
              onChange={(e) => {
                const regexTest =
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (regexTest.test(e.target.value)) {
                  document.querySelector(".input--email").style.boxShadow =
                    "green 4px 4px 0 0, #141517  4px 4px 0 1px";
                  setEmail({
                    value: e.target.value,
                    isValid: true,
                  });
                } else {
                  document.querySelector(".input--email").style.boxShadow =
                    "red 4px 4px 0 0, #141517 4px 4px 0 1px";
                  setEmail({
                    value: e.target.value,
                    isValid: false,
                  });
                }
              }}
            />
          </div>
          <div className="contact__form__container">
            <label className="contact__form--labels contact--text">
              Nom de l'entreprise
            </label>
            <input
              type="text"
              className="contact__form--inputs"
              style={{
                boxShadow: "#141517 4px 4px 0 0, #141517 4px 4px 0 1px",
              }}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <div className="contact__form__container">
            <label className="contact__form--labels contact--text">
              Message *
            </label>
            <textarea
              className="contact__form--area"
              value={message.value}
              onChange={(e) => {
                if (e.target.value.length > 500) {
                  return;
                }
                setMessageLength(e.target.value.length);
                if (e.target.value.length > 10 && e.target.value.length < 501) {
                  document.querySelector(
                    ".contact__form--area"
                  ).style.boxShadow =
                    "green 4px 4px 0 0, #141517  4px 4px 0 1px";
                  setMessage({
                    value: e.target.value,
                    isValid: true,
                  });
                } else {
                  document.querySelector(
                    ".contact__form--area"
                  ).style.boxShadow = "red 4px 4px 0 0, #141517 4px 4px 0 1px";
                  setMessage({
                    value: e.target.value,
                    isValid: false,
                  });
                }
              }}
            />
            <div className="contact__form--areaLength__container">
              <p
                className="contact__form--areaLength__container--text"
                style={{
                  color:
                    messageLength > 10 && messageLength < 501 ? "green" : "red",
                }}
              >
                {messageLength}/500
              </p>
            </div>
          </div>
          <p
            style={{
              color: "red",
              display: showErrorMessage.show ? "flex" : "none",
              textAlign: "center",
            }}
          >
            {showErrorMessage.value}
          </p>
          <p
            style={{
              color: "green",
              textAlign: "center",
              margin: 0,
              display: showSuccessMessage ? "flex" : "none",
            }}
          >
            Message envoyé !<br />
            Je vous contacterai sous 24 heures.
          </p>
          <button
            className="bottomNav--prev"
            style={{
              boxShadow: "none",
              margin: 0,
              marginTop: 25,
              cursor: "pointer",
              zIndex: 2,
            }}
            onClick={(e) => handleSubmit(e)}
          >
            Envoyer
          </button>
        </form>
        <BottomNav swipeToPrev={swipeToPrev} />
      </div>
      <Footer setSwipeToPrev={setSwipeToPrev} />
    </div>
  );
};

export default Contact;
