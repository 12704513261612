import React from "react";

const SloppedText = ({ text, style, styleText, unSwipeable }) => {
  return (
    <div
      className={`sloppedText ${unSwipeable && "unSwipeable"}`}
      style={style ? style : {}}
    >
      <p
        className={`sloppedText--text ${unSwipeable && "unSwipeable"}`}
        style={styleText ? styleText : {}}
      >
        {text}
      </p>
    </div>
  );
};

export default SloppedText;
