import React, { useState, useEffect } from "react";
import BottomNav from "../components/BottomNav";
import StarryBackground from "../components/StarryBackground";
import SloppedText from "../components/SloppedText";
import Subtitle from "../components/Subtitle";
import ListCenterCard from "../components/ListCenterCard";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import RecommandationCard from "../components/RecommandationCard";
import Tag from "../components/Tag";
import Footer from "../components/Footer";
import { useSwipeable } from "react-swipeable";
import { useDimensions } from "../hooks/useDimensions";
import { delay } from "../hooks/exportableFuntions";
import { useFirebase } from "../hooks/useFirebase";

const About = () => {
  const [recommandationIndex, setRecommandationIndex] = useState(0);
  const [swipeToNext, setSwipeToNext] = useState(false);
  const [swipeToPrev, setSwipeToPrev] = useState(false);
  const { screenWidth } = useDimensions();
  const { recommandations, user } = useFirebase();

  const experiences = [
    <p className="about--text about__actualJob--container">
      <strong>Altior</strong> - Lociel ERP nouvelle génération.
      <br />
      Développeur front-end -<strong> depuis janvier 2024</strong>.
      <div className="about__actualJob__tag">
        <p className="about__actualJob__tag--text">Actuellement en poste</p>
      </div>
    </p>,
    <p className="about--text">
      <strong>AMSILabs</strong> - Agence web & mobile.
      <br />
      Développeur web & mobile en alternance -<strong> 1 an</strong>.
    </p>,
    <p className="about--text">
      <strong>Aportee2main</strong> - Plateforme de vente en ligne.
      <br />
      Développeur web en alternance -<strong> 8 mois</strong>.
    </p>,
  ];

  const diplomes = [
    <p className="about--text">
      Développeur d'application javascript React - 2023
      <br />
      RNCP niveau 6 (bac +3/4) - Openclassrooms (alternance).
    </p>,
    <p className="about--text">
      Développeur web - 2021
      <br />
      RNCP niveau 5 (bac +2) - Openclassrooms.
    </p>,
    <p className="about--text">
      Baccalauriat Général - 2018
      <br />
      Bac S, spé. SVT.
    </p>,
  ];

  useEffect(() => {
    (async () => {
      await delay(900);
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    })();
  }, []);

  const handleNextRecommandation = () => {
    if (recommandations.length === recommandationIndex + 1) {
      setRecommandationIndex(0);
      return;
    }
    setRecommandationIndex((prev) => prev + 1);
  };

  const handlePreviousRecommandation = () => {
    if (recommandationIndex === 0) {
      setRecommandationIndex(recommandations.length - 1);
      return;
    }
    setRecommandationIndex((prev) => prev - 1);
  };

  // navigation by swipe
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setSwipeToNext(true);
    },
    onSwipedRight: () => {
      setSwipeToPrev(true);
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    delta: user?.swipeDelta ?? 75,
  });

  return (
    <>
      <div {...handlers} className="about scrollDiv">
        <StarryBackground />
        <BottomNav swipeToNext={swipeToNext} swipeToPrev={swipeToPrev} />
        <SloppedText
          text={"A propos de moi"}
          styleText={{ fontSize: screenWidth < 1100 ? 30 : 60 }}
        />
        <div className="about__resume about--category">
          <Subtitle
            text={"Résumé"}
            style={{ fontSize: screenWidth < 1100 ? 20 : 30 }}
          />
          <p className="about--text">
            💼 <strong>Poste Actuel:</strong> Développeur front-end chez Altior{" "}
            <br />
            🔧 <strong>Technologies maîtrisées:</strong> JavaScript, HTML, CSS{" "}
            <br />
            🌐 <strong>Spécialité:</strong> Développement d'applications web et
            mobiles performantes avec une expertise particulière dans les
            framework React et React Native.
            <br />
            <div style={{ height: 15 }}></div>✨{" "}
            <strong>À propos de moi:</strong> Ce qui me motive chaque jour,
            c'est la possibilité de contribuer à l'innovation technologique. Mon
            engagement envers l'apprentissage continu me permet de rester à jour
            avec les dernières tendances du développement, toujours prêt à
            explorer de nouveaux horizons.
            <br />
            <div style={{ height: 15 }}></div>
            👨‍💻 <strong>Poste actuel:</strong> Chez Altior, je suis au cœur d'une
            équipe dynamique, participant activement au développement d'un
            logiciel ERP de pointe. Mon rôle consiste à concrétiser des idées en
            fonctionnalités robustes, assurant ainsi aux utilisateurs la
            meilleure expérience possible.
            <br />
            <div style={{ height: 15 }}></div>
            🚀 <strong>Projets récents:</strong> Récemment, j'ai créé une
            application mobile personnalisée axée sur la gestion et l'impression
            de documents pour un client. Mon implication s'est étendue à toutes
            les étapes du projet, de la conception initiale du cahier des
            charges à la finalisation du design, englobant également le
            développement front et back-end.
            <br />
            <div style={{ height: 15 }}></div>
            🤝 <strong>Ouverture aux collaborations:</strong> Je suis ouvert à
            la collaboration avec des esprits passionnés et des projets
            stimulants. N'hésitez pas à me contacter si vous souhaitez discuter
            de nouvelles idées, de projets passionnants ou simplement pour
            échanger sur le monde du développement web et mobile.
          </p>
        </div>
        <div className="about__experience about--category">
          <Subtitle
            text={"Expériences"}
            style={{ fontSize: screenWidth < 1100 ? 20 : 30 }}
          />
          <div className="about__experience__centerContainer">
            <ListCenterCard data={experiences} />
          </div>
        </div>
        <div className="about__diplome about--category">
          <Subtitle
            text={"Diplômes"}
            style={{ fontSize: screenWidth < 1100 ? 20 : 30 }}
          />
          <div className="about__experience__centerContainer">
            <ListCenterCard data={diplomes} />
          </div>
        </div>
        <div className="about__reco about--category">
          <Subtitle
            text={"Recommandations"}
            style={{ fontSize: screenWidth < 1100 ? 20 : 30 }}
          />
          <div className="about__reco__centerContainer">
            <div className="about__reco__centerContainer__header">
              <div
                className="about__reco__centerContainer__header--button"
                onClick={handlePreviousRecommandation}
              >
                <KeyboardDoubleArrowLeftIcon style={{ color: "white" }} />
              </div>
              <div className="about__reco__centerContainer__header--indexContainer">
                <p className="about__reco__centerContainer__header--index">
                  {recommandationIndex + 1}/{recommandations.length}
                </p>
              </div>
              <div
                className="about__reco__centerContainer__header--button reverse"
                onClick={handleNextRecommandation}
              >
                <KeyboardDoubleArrowLeftIcon style={{ color: "white" }} />
              </div>
            </div>
            <RecommandationCard data={recommandations[recommandationIndex]} />
          </div>
        </div>
        <div className="about__stack about--category">
          <Subtitle
            text={"Stack"}
            style={{ fontSize: screenWidth < 1100 ? 20 : 30 }}
          />
          <div className="about__stack__tagContainer">
            {user?.stack.map((el) => (
              <Tag text={el} />
            ))}
          </div>
        </div>
        <div className="about__soft about--category">
          <Subtitle
            text={"Soft Skills"}
            style={{ fontSize: screenWidth < 1100 ? 20 : 30 }}
          />
          <div className="about__stack__tagContainer">
            {user?.softSkills.map((el) => (
              <Tag text={el} />
            ))}
          </div>
        </div>
      </div>
      <Footer setSwipeToNext={setSwipeToNext} setSwipeToPrev={setSwipeToPrev} />
    </>
  );
};

export default About;
