import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const MiniCard = ({
  data,
  setShowBigCard,
  projectIndex,
  setSelectedProject,
  resetIndexCarousel,
}) => {
  const [autoplay, setAutoplay] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAutoplay(true);
    }, 5000);
  }, []);

  return (
    <div
      className="miniCard"
      onClick={() => {
        setSelectedProject(projectIndex);
        resetIndexCarousel();
        document
          .querySelector(".bigCard")
          .classList.add("bigCard--animBackdrop");
        document
          .querySelector(".bigCard__content")
          .classList.add("bigCard--animOpen");
        setShowBigCard(true);
      }}
    >
      <div className="miniCard__top">
        <div className="miniCard__top__center unSwipeable">
          <Carousel
            showArrows={false}
            autoPlay={autoplay}
            interval={6000}
            infiniteLoop={true}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
          >
            {data?.images.map((el) => {
              return (
                <div className="miniCard__top__center__imgContainer unSwipeable">
                  <img
                    src={el}
                    className="miniCard__top__center__imgContainer--img"
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <div className="miniCard__bottom">
        <div className="miniCard__bottom--titleContainer">
          <h2 className="miniCard__bottom--title">
            {data?.title.toUpperCase()}
          </h2>
          <p>{data?.isMobile ? "~Mobile" : "~Web"}</p>
        </div>
        <div className="miniCard__bottom__spanContainer">
          {data?.technos.map((el, i) => {
            if (i > 2) return;
            return (
              <div className="miniCard__bottom__span">
                <p className="miniCard__bottom__span--text">{el}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MiniCard;
