import logo from "./logo.svg";
import "./styles/main.scss";
import Router from "./navigation/Router";
import FirebaseContextProvider from "./hooks/useFirebase";
import DimensionsContextProvider from "./hooks/useDimensions";

function App() {
  return (
    <div className="App">
      <FirebaseContextProvider>
        <DimensionsContextProvider>
          <Router></Router>
        </DimensionsContextProvider>
      </FirebaseContextProvider>
    </div>
  );
}

export default App;
