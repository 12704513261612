import React, { useState } from "react";
import BottomNav from "../components/BottomNav";
import StarryBackground from "../components/StarryBackground";
import Logo from "../components/Logo";
import SloppedText from "../components/SloppedText";
import { useSwipeable } from "react-swipeable";
import { useDimensions } from "../hooks/useDimensions";
import Typewriter from "typewriter-effect";
import { useFirebase } from "../hooks/useFirebase";

const Home = () => {
  const { user } = useFirebase();
  const { screenHeight, screenWidth } = useDimensions();
  const [swipeToNext, setSwipeToNext] = useState(false);
  const logos = [
    require("./../assets/images/react_logo.png"),
    require("./../assets/images/expo_logo.png"),
    require("./../assets/images/redux_logo.png"),
    require("./../assets/images/nodejs_logo.png"),
    require("./../assets/images/firebase_logo.png"),
  ];

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setSwipeToNext(true);
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    delta: user?.swipeDelta ?? 75,
  });

  return (
    <div {...handlers} className="home hiddenScrollForTrans scrollDiv">
      <StarryBackground />
      <BottomNav isHome={true} swipeToNext={swipeToNext} />
      <div className="home__leftContainer" style={{ position: "relative" }}>
        <div className="home__leftContainer__imgBorder">
          <img
            className="home__leftContainer__imgBorder--img"
            src={user?.image}
          />
        </div>
        {screenWidth > 1100 && (
          <div className="home__leftContainer__logoContainer">
            {logos.map((el) => (
              <Logo logo={el} />
            ))}
          </div>
        )}
      </div>
      {screenWidth > 1100 && <div className="home__separator"></div>}
      <div className="home__rightContainer">
        <p
          className="home__rightContainer--text"
          style={screenWidth < 1100 ? { marginBottom: 10 } : {}}
        >
          Je suis
        </p>
        <SloppedText
          text={"Salim Camara"}
          styleText={{
            fontSize: screenWidth < 1100 ? "40px" : "80px",
            color: "white",
          }}
        />
        <p
          className="home__rightContainer--text"
          style={{
            margin: screenWidth < 1100 ? "10px 0 10px 0" : "30px 0 30px 0",
          }}
        >
          développeur web & mobile
        </p>
        <p className="home__rightContainer--text">
          Bienvenue sur mon portfolio !
        </p>
      </div>
      {screenWidth < 1100 && (
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
            delay: 50,
            pauseFor: 5000,
            strings: ["Swipez pour commencer >>"],
            wrapperClassName: "wapperHome",
            cursorClassName: "cursorHome",
          }}
        />
      )}
      {screenWidth < 1100 && (
        <div className="home__leftContainer__logoContainer logoContainerMobile">
          {logos.map((el) => (
            <Logo logo={el} />
          ))}
        </div>
      )}
      {/* <InlineMenu /> */}
    </div>
  );
};

export default Home;
