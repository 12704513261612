import React, { useEffect, useState } from "react";
import { useFirebase } from "../../hooks/useFirebase";

const HelloTrans = () => {
  const [textIndex, setTextIndex] = useState(0);
  const [unableToStart, setUnableToStart] = useState(false);
  const [tmpVar, setTmpVar] = useState(false);
  const { user } = useFirebase();

  // const loadImage = () => {
  //   return new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.src = user?.image;
  //     console.log("l13 ", img);
  //     img.onload = () => {
  //       setTmpVar(true);
  //       resolve();
  //     };
  //   });
  // };

  // const waitingUrlImg = async () => {
  //   (await user?.image) && setTmpVar(true);
  // };

  // useEffect(() => {
  //   (async () => {
  //     console.log("LOADING");
  //     await waitingUrlImg();
  //   })();
  // }, []);

  // useEffect(() => {
  //   tmpVar === true && console.log("l31 success");
  // }, [tmpVar]);

  const helloTranslated = [
    "Hello",
    "안녕하세요",
    "Hola",
    "مرحبًا",
    "привет",
    "Γειά σου",
    "नमस्ते",
    "Bonjour",
  ];

  // wait for all data from firebase, also all images of projects
  const loadingData = () => {
    setTimeout(() => {
      setUnableToStart(true);
    }, 2000);
  };

  useEffect(() => {
    !unableToStart && loadingData();
    if (textIndex !== helloTranslated.length - 1 && unableToStart) {
      setTimeout(
        () => {
          setTextIndex(textIndex + 1);
        },
        textIndex === 0 ? 1500 : 200
      );
      document
        .querySelector(".helloTrans")
        .classList.add("helloTrans--unableToStart");
    }
  }, [textIndex, unableToStart]);

  return (
    <div className="helloTrans">
      <div className="helloTrans--dot"></div>
      <p className="helloTrans--text">
        {!unableToStart ? "Hello" : helloTranslated[textIndex]}
      </p>
    </div>
  );
};

export default HelloTrans;
