import React, { useState } from "react";
import DotText from "./DotText";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { useDimensions } from "../hooks/useDimensions";
import { useFirebase } from "../hooks/useFirebase";

const Menu = ({ setNewPage, setShowButtons }) => {
  const route = useLocation();
  const { user } = useFirebase();
  const pathname = route?.pathname;

  return (
    <div className="menu">
      <div
        className="menu__left"
        onClick={() => {
          document.querySelector(".menu").classList.remove("menu--open");
          document.querySelector(".menu").classList.add("menu--close");
          setShowButtons(true);
        }}
      ></div>
      <div className="menu__right">
        <div
          className="menu__right--crossContainer"
          onClick={() => {
            document.querySelector(".menu").classList.remove("menu--open");
            document.querySelector(".menu").classList.add("menu--close");
            setShowButtons(true);
          }}
        >
          <CloseIcon
            style={{
              color: "#999d9e",
              cursor: "pointer",
              height: 40,
              width: 40,
            }}
          />
        </div>
        <div className="menu__right--top">
          <p className="menu__right--subtitle">Navigation</p>
          <div className="menu__right--separator"></div>
          <div className="menu__right--menuContainer">
            <DotText
              text={"Home"}
              styleText={{
                marginBottom: 30,
              }}
              isActive={pathname === "/" ? true : false}
              pageIndex={0}
              setNewPage={setNewPage}
            />
            <DotText
              text={"Qui suis-je ?"}
              isActive={pathname === "/about" ? true : false}
              styleText={{
                marginBottom: 30,
              }}
              pageIndex={1}
              setNewPage={setNewPage}
            />
            <DotText
              text={"Mes projets"}
              styleText={{
                marginBottom: 30,
              }}
              isActive={pathname === "/portfolio" ? true : false}
              pageIndex={2}
              setNewPage={setNewPage}
            />
            <DotText
              text={"Contact"}
              isActive={pathname === "/contact" ? true : false}
              pageIndex={3}
              setNewPage={setNewPage}
            />
          </div>
        </div>
        <div className="menu__right--bottom">
          <p className="menu__right--subtitle">Réseaux</p>
          <div className="menu__right--separator"></div>
          <div className="menu__right--socialContainer">
            <p
              style={{ color: "white", cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `${
                    user?.linkedin ??
                    "https://www.linkedin.com/in/salim-camara-879b411b8/"
                  }`,
                  "_blank"
                )
              }
            >
              LinkedIn
            </p>
            <p
              style={{ color: "white", marginLeft: 10, cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `${user?.github ?? "https://github.com/Salim-camara"}`,
                  "_blank"
                )
              }
            >
              Github
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
