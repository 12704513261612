export function simulateLeftArrowKeyClick() {
  const event = new KeyboardEvent("keydown", {
    key: "ArrowLeft",
    code: "ArrowLeft",
    keyCode: 37,
    which: 37,
    altKey: false,
    ctrlKey: false,
    shiftKey: false,
    metaKey: false,
  });
  window.dispatchEvent(event);
}

export function simulateRightArrowKeyClick() {
  const event = new KeyboardEvent("keydown", {
    key: "ArrowRight",
    code: "ArrowRight",
    keyCode: 39,
    which: 39,
    altKey: false,
    ctrlKey: false,
    shiftKey: false,
    metaKey: false,
  });
  window.dispatchEvent(event);
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const projectsMock = [
  {
    images: [
      require("./../assets/images/firebase_logo.png"),
      require("./../assets/images/react_logo.png"),
    ],
    title: "MockTest",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore cumque minus ducimus atque eligendi earum quos obcaecati animi ullam voluptate, nulla, possimus quas! Numquam ab sed fugit aut provident repellat? Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit, minus ut consectetur ducimus facilis saepe qui neque illo tenetur id at maxime, voluptate sunt debitis, quidem deleniti culpa? Eius, quasi!",
    isMobile: true,
    technos: ["React", "React Native"],
    duration: 1,
    points: ["test", "test"],
  },
];
