import React, { useState, useEffect, useContext, createContext } from "react";

export const Dimensions = createContext();
export const useDimensions = () => useContext(Dimensions);

const DimensionsContextProvider = (props) => {
  const [screenHeight, setScreenheight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    setScreenWidth(window.screen.width);
    setScreenheight(window.screen.height);
  }, []);

  return (
    <Dimensions.Provider
      value={{
        screenHeight,
        screenWidth,
      }}
    >
      {props.children}
    </Dimensions.Provider>
  );
};

export default DimensionsContextProvider;
