import React from "react";

const Subtitle = ({ text, style }) => {
  return (
    <div className="subtitle" style={style ? style : {}}>
      <p className="subtitle--text">{text}</p>
      <div className="subtitle--underline"></div>
    </div>
  );
};

export default Subtitle;
