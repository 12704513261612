import React, { useEffect } from "react";
import HelloTrans from "./HelloTrans";
import { useLocation, useNavigate } from "react-router-dom";
import LeftToRightEmpty from "./LeftToRightEmpty";
import CenterToLeft from "./CenterToLeft";
import RightToLeftEmpty from "./RightToLeftEmpty";
import CenterToRight from "./CenterToRight";

let menuNewPage = "";

export const navigateToNextPage = (nav, menuNewPageProp) => {
  menuNewPage = menuNewPageProp;
  document
    .querySelector(".leftToRightEmpty")
    .classList.add("leftToRightEmpty--startAnim");
  // document.querySelector(".scrollDiv").classList.add("hiddenScrollForTrans");
  setTimeout(() => {
    nav();
  }, 500);
};

export const navigateMenuToNextPage = ({ nav, menuNewPageProp }) => {
  menuNewPage = menuNewPageProp;
  document
    .querySelector(".leftToRightEmpty")
    .classList.add("leftToRightEmpty--startAnim");
  // document.querySelector(".scrollDiv").classList.add("hiddenScrollForTrans");
  setTimeout(() => {
    nav();
  }, 500);
};

export const navigateToPrevPage = (nav) => {
  document
    .querySelector(".rightToLeftEmpty")
    .classList.add("rightToLeftEmpty--startAnim");
  // document.querySelector(".scrollDiv").classList.add("hiddenScrollForTrans");
  setTimeout(() => {
    nav();
  }, 500);
};

const TransitionHandler = ({
  isHome,
  disableHomeTrans,
  isNextPage,
  isPrevPage,
  actualPage,
  newPage,
}) => {
  const route = useLocation();

  return (
    <>
      {isHome && !disableHomeTrans && <HelloTrans />}
      {/* next */}
      <LeftToRightEmpty text={newPage} />
      {isNextPage && <CenterToLeft text={actualPage} />}
      {/* previous */}
      <RightToLeftEmpty text={newPage} />
      {isPrevPage && <CenterToRight text={actualPage} />}
    </>
  );
};

export default TransitionHandler;
