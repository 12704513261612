import React from "react";
import { allPages, pagesTranslate } from "./BottomNav";
import { useLocation, useNavigate } from "react-router-dom";
import {
  navigateToNextPage,
  navigateToPrevPage,
} from "./transition/TransitionHandler";

const DotText = ({
  style,
  styleText,
  styleDot,
  text,
  isActive,
  pageIndex,
  setNewPage,
}) => {
  const route = useLocation();
  const actualIndex = allPages.findIndex((item) => route.pathname === item);
  const navigate = useNavigate();

  const handleNav = async () => {
    if (actualIndex < pageIndex) {
      setNewPage(pagesTranslate[allPages[pageIndex]]);
      navigateToNextPage(() => {
        navigate(allPages[pageIndex], {
          state: { previousUrl: route.pathname, isNextPage: true },
        });
      });
    } else {
      setNewPage(pagesTranslate[allPages[pageIndex]]);
      navigateToPrevPage(() => {
        navigate(allPages[pageIndex], {
          state: { previousUrl: route.pathname, isPrevPage: true },
        });
      });
    }
  };

  return (
    <div
      className="dotText"
      style={style && style}
      onClick={() => pageIndex !== actualIndex && handleNav()}
    >
      <p
        className="dotText--text"
        style={
          pageIndex !== actualIndex
            ? { cursor: "pointer", ...styleText }
            : { ...styleText }
        }
      >
        {text}
      </p>
      {isActive && (
        <div className="dotText--dot" style={styleDot && styleDot}></div>
      )}
    </div>
  );
};

export default DotText;
