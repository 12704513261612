import React from "react";

const Logo = ({ logo, style }) => {
  return (
    <div className="logo" style={style}>
      <img src={logo} style={{ height: "60%", borderRadius: "100%" }} />
    </div>
  );
};

export default Logo;
