import React, { useEffect, useState } from "react";
import MiniCard from "../components/projects/MiniCard";
import BigCard from "../components/projects/BigCard";
import { useFirebase } from "../hooks/useFirebase";
import BottomNav from "../components/BottomNav";
import StarryBackground from "../components/StarryBackground";
import SloppedText from "../components/SloppedText";
import Subtitle from "../components/Subtitle";
import { useSwipeable } from "react-swipeable";
import { useDimensions } from "../hooks/useDimensions";
import Footer from "../components/Footer";
import { delay } from "../hooks/exportableFuntions";
import { projectsMock } from "../hooks/exportableFuntions";

const Projects = () => {
  const { projects, user } = useFirebase();
  const { screenWidth } = useDimensions();
  const [showBigCard, setShowBigCard] = useState(false);
  const [selectedProject, setSelectedProject] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [swipeToNext, setSwipeToNext] = useState(false);
  const [swipeToPrev, setSwipeToPrev] = useState(false);

  const resetIndexCarousel = () => {
    setCurrentIndex(0);
  };

  useEffect(() => {
    (async () => {
      await delay(900);
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    })();
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: (e) => {
      if (e.event.target.classList.value.includes("unSwipeable")) return;
      setSwipeToNext(true);
    },
    onSwipedRight: (e) => {
      if (e.event.target.classList.value.includes("unSwipeable")) return;
      setSwipeToPrev(true);
    },
    swipeDuration: 500,
    preventScrollOnSwipe: false,
    delta: user?.swipeDelta ?? 75,
  });
  return (
    <>
      <div
        {...handlers}
        className="projects scrollDiv"
        // style={showBigCard ? { overflowY: "hidden", overflowX: "hidden" } : {}}
      >
        <StarryBackground />
        <BigCard
          isVisible={showBigCard}
          setIsVisible={setShowBigCard}
          data={projects[selectedProject]}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
        />
        <div className="projects__header">
          <SloppedText
            text={"Portfolio"}
            styleText={{ fontSize: screenWidth < 1100 ? 30 : 60 }}
          />
        </div>
        <Subtitle
          text={`${projects.length} projets au total`}
          style={{ fontSize: screenWidth < 1100 ? 20 : 30 }}
        />
        <div className="projects__content">
          {projects.map((el, i) => {
            return (
              <MiniCard
                data={el}
                showBigCard={showBigCard}
                setShowBigCard={setShowBigCard}
                setSelectedProject={setSelectedProject}
                projectIndex={i}
                resetIndexCarousel={resetIndexCarousel}
              />
            );
          })}
        </div>
        <BottomNav swipeToNext={swipeToNext} swipeToPrev={swipeToPrev} />
      </div>
      <Footer setSwipeToNext={setSwipeToNext} setSwipeToPrev={setSwipeToPrev} />
    </>
  );
};

export default Projects;
