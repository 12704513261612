import React from "react";

const Tag = ({ text }) => {
  return (
    <div className="tag">
      <p className="tag--text">{text}</p>
    </div>
  );
};

export default Tag;
