import React from "react";
import ReactHtmlParser from "react-html-parser";

const RecommandationCard = ({ data }) => {
  return (
    <div className="recommandationCard">
      <div className="recommandationCard__top">
        <div className="recommandationCard__top__imgTitle">
          <img
            className="recommandationCard__top__imgTitle--img"
            src={data?.image && data.image}
          />
          <p className="recommandationCard__top__imgTitle--title">
            {data?.firstName} {data?.lastName}
          </p>
        </div>
        <div className="recommandationCard__top__subTitle">
          <p className="recommandationCard__top__subTitle--poste">
            {data?.status}
          </p>
        </div>
      </div>
      <div className="recommandationCard__bottom">
        {/* <p className="recommandationCard__bottom--text">{data?.message}</p> */}
        {data && ReactHtmlParser(data?.message)}
      </div>
    </div>
  );
};

export default RecommandationCard;
