import React from "react";

const ListCenterCard = ({ data }) => {
  return (
    <div className="listCenterCard">
      {data?.map((el) => {
        return (
          <div className="listCenterCard__item">
            <div className="listCenterCard__item--dot"></div>
            {el}
          </div>
        );
      })}
    </div>
  );
};

export default ListCenterCard;
